// extracted by mini-css-extract-plugin
export var column = "ImageGallery__column__jQHVK";
export var flex = "ImageGallery__flex__HQI0M";
export var flexColumn = "ImageGallery__flexColumn__z_Leb";
export var galleryChevron = "ImageGallery__galleryChevron__LfsO_";
export var galleryClose = "ImageGallery__galleryClose__siyr5";
export var galleryContainer = "ImageGallery__galleryContainer__fj41d";
export var galleryCount = "ImageGallery__galleryCount__SBRcl";
export var galleryImageDescriptionContainer = "ImageGallery__galleryImageDescriptionContainer__ZbNwq";
export var galleryImageHidden = "ImageGallery__galleryImageHidden__Efi63";
export var galleryLeft = "ImageGallery__galleryLeft__GOn42";
export var galleryRight = "ImageGallery__galleryRight__WGTqw";
export var gap1 = "ImageGallery__gap1__r3xqI";
export var gap2 = "ImageGallery__gap2__vy1UY";
export var gap3 = "ImageGallery__gap3__jGQNa";
export var gap4 = "ImageGallery__gap4__FyUg6";
export var gap5 = "ImageGallery__gap5__sMDFf";
export var imageGalleryContainer = "ImageGallery__imageGalleryContainer__PZtrv";
export var row = "ImageGallery__row__YLJnL";